<template>
  <div class="select-none">
    <en-radio v-for="item of type.data" v-model="type.active" :label="item.code">{{ `${item.description}-${item.message}` }}</en-radio>
    <fieldset class="border-1 border-solid border-neutral-300 text-xs">
      <div @click.capture="body.click" :ref="setRef('contentRef')" class="relative w-[900px] px-[10px] py-[20px] flex">
        <img src="@service//assets/model-outer.png" class="w-1/2 h-full" />
        <img src="@service/assets/model-inner.png" class="w-1/2 h-full" />
        <span
          v-for="item of localValue"
          class="absolute cursor-pointer text-red-500"
          :style="{ left: `${item.x}px`, top: `${item.y}px`, lineHeight: 1 }"
          @mousedown="body.mousedown(item)"
        >
          {{ item.type?.description }}
        </span>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { toRaw } from 'vue'

export default factory({
  props: {
    modelValue: Array as PropType<EnocloudServiceDefinitions['ServiceDamageDto'][]>,
    disabled: Boolean as PropType<boolean>
  },

  emits: {
    'update:model-value': (value: EnocloudServiceDefinitions['ServiceDamageDto'][]) => typeof value === 'object'
  },

  computed: {
    localValue() {
      return this.modelValue
    }
  },

  config: {
    children: {
      type: {
        active: '',
        ajax: {
          get: {
            action: 'GET /enocloud/common/lookup/:lookupType',
            data: 'array',
            loading: true,
            params(params) {
              params.paths = ['DMGTYP']
            }
          }
        },
        computed: {
          current() {
            return this.type.data.find((item) => item.code === this.type.active)
          }
        }
      },
      body: {
        enableClick: true,
        active: null as EnocloudServiceDefinitions['ServiceDamageDto'] | null,
        click(e: MouseEvent) {
          const value = [...(this.localValue ?? [])].map(toRaw)
          if (this.type.current && this.body.enableClick) {
            const el = e.target as HTMLSpanElement
            value.push({ type: this.type.current, x: e.offsetX + el.offsetLeft - 5, y: e.offsetY + el.offsetTop - 7 })
            this.emit('update:model-value', value)
          }
        },
        mousedown(data: EnocloudServiceDefinitions['ServiceDamageDto']) {
          this.body.active = data

          const mousemove = (e: MouseEvent) => {
            this.body.enableClick = false
            if (this.body.active) {
              this.body.active.x = this.body.active.x! + e.movementX
              this.body.active.y = this.body.active.y! + e.movementY
            }
          }

          const mouseup = (e: MouseEvent) => {
            const el = e.target as HTMLSpanElement
            const contentRef = this.refs.contentRef as HTMLDivElement
            if (this.body.active) {
              if (
                this.body.active.x! >= 0 &&
                this.body.active.x! <= contentRef.clientWidth - el.clientWidth &&
                this.body.active.y! >= 0 &&
                this.body.active.y! <= contentRef.clientHeight - el.clientHeight
              ) {
                el.style.cursor = 'pointer'
              } else {
                const value = [...(this.localValue ?? [])].map(toRaw)
                const index = value.findIndex((item) => item.x === this.body.active?.x && item.y === this.body.active?.y)
                if (index > -1) value.splice(index, 1)
                this.emit('update:model-value', value)
              }
            }

            document.removeEventListener('mousemove', mousemove)
            document.removeEventListener('mouseup', mouseup)

            setTimeout(() => (this.body.enableClick = true))
          }

          document.addEventListener('mousemove', mousemove)
          document.addEventListener('mouseup', mouseup)

          return false
        }
      }
    }
  },

  mounted() {
    this.type.get()
  }
})
</script>
